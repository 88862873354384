import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import TableContainer from "@mui/material/TableContainer";
import React, {useState} from "react";
import {AppointmentAuditDialog} from "./AppointmentAuditDialog";
import {Grid} from "@mui/material";

export default function AppliedCarrierView(props) {

    const {appliedCarriers, isLoading} = props;
    const [selectedRow, setSelectedRow] = useState();
    const [openModal, setOpenModal] = React.useState(false);

    const handleOpenModal = (row) => {
        setSelectedRow(row);
        setOpenModal(true);
    }
    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedRow(undefined);
    }

    return (
        <Grid item xs={12}>
            {selectedRow &&
                <AppointmentAuditDialog dfsSearch="" open={openModal} onClose={handleCloseModal}
                                        data={selectedRow} carrierView={true}/>}
            {!isLoading && <TableContainer component={Paper} sx={{marginTop: '18px'}}>
                <Table>
                    <TableHead>
                        <TableRow sx={{backgroundColor: '#0066a1 !important'}}>
                            <TableCell sx={{fontWeight: "bold", color: '#fff'}}>Carrier Name</TableCell>
                            <TableCell sx={{fontWeight: "bold", color: '#fff'}}>Company Code</TableCell>
                            <TableCell sx={{fontWeight: "bold", color: '#fff'}}>Fein</TableCell>
                            <TableCell sx={{fontWeight: "bold", color: '#fff'}}>Audit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(appliedCarriers && (appliedCarriers.agentCarrierWrapperData) && (appliedCarriers.agentCarrierWrapperData.length > 0)) &&
                            (appliedCarriers?.agentCarrierWrapperData?.map((row) => (<TableRow
                                key={Math.random()}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell>
                                 <span>
                                {row.agentCarrierData.companyName.split('|').map((company, index) => (
                                    <span key={index} style={{display: 'block'}}>{company}</span>
                                ))}
                                </span>
                                </TableCell>
                                <TableCell>{row.agentCarrierData.companyCode}</TableCell>
                                <TableCell>{row.agentCarrierData.fein}</TableCell>
                                <TableCell>
                                    {row?.agentAppointmentAuditData && row.agentAppointmentAuditData.length > 0 &&
                                        (<Button
                                            variant="outlined"
                                            color="info"
                                            onClick={() => handleOpenModal(row)}
                                        >
                                            {'View Audit'}
                                        </Button>)
                                    }
                                </TableCell>
                            </TableRow>)))}
                    </TableBody>
                </Table>
            </TableContainer>}
        </Grid>);
}